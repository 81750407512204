const activityInfo = {
    activity_uuid: "",
    activity_title: "",
    motion_category_two: [],
    target_category_zero: [],
    activity_type: 0,
    province: "",
    city: "",
    county: "",
    region_code: 0,
    road: "",
    address: "",
    longitude: 0,
    latitude: 0,
    activity_phone: "",
    store_uuid_all: [],
    quota: 0,
    signup_start_time: "1",
    signup_end_time: "1",
    detailed_time: [{
        activity_date: '',
        activity_time: ['00:00', '23:59'],
    },],
    video: "",
    photo: "",
    originally_price: 0,
    promotion_price: 0,
    is_refund: 1,
    is_refund_end_time: "",
    introduce: "",
    attention: "",
    details_rich_text: "",
    cover: ""
}

export default activityInfo;