<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product/activity' }">活动列表</el-breadcrumb-item>
                <el-breadcrumb-item>{{ type === 'copy' ? '活动复制' : '详情' }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!-- 复制活动 -->
            <Copy v-if="type === 'copy'" />
            <!--详情-->
            <Edit v-else />

        </div>
    </div>
</template>
<script>
import Edit from './page/Edit';
import Copy from "./page/Copy";

export default {
    components: {
        Edit, Copy
    },
    data() {
        return {
            page_name: '活动详情',
            type: '',
        }
    },
    // 创建
    created() {
        let type = this.$route.query.type;
        this.type = type;

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
